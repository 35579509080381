/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import MyRadioInfo from "./MyRadioInfo";
import { Dialog } from 'vant';
export default {
  components: {
    MyRadioInfo
  },
  data() {
    return {
      searchVal: "",
      showInfo: false,
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: {},
      itemString: "",
      gysInfoData: {}
    };
  },
  created() {
    this.getCgwpInfo();
    this.data = this.mount;
  },
  mounted() {},
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      let formdata = new FormData();
      formdata.append("pageIndex", pageNum);
      formdata.append("pageSize", this.pageSize);
      if (this.searchVal != "") {
        formdata.append("Q_CJMC__S_LK", this.searchVal);
      }
      var url = _baseUrl + `/dev/cus/customData/gys/getData.do`;
      this.$ajax.post(url, formdata).then(res => {
        this.data = res.data.data;
        this.data.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.identifyLabel) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 20);
        console.log(this.mount.length);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      var pageNum = this.pageNum;
      var maxPageNum = this.maxPageNum;
      if (pageNum <= maxPageNum) {
        // this.prePage = pageNum;
        this.getCgwpInfo();
      } else {
        Dialog({
          message: '请正确输入页数！'
        });
      }
    },
    //搜索
    onSearch() {
      this.pageNum = 1;
      this.getCgwpInfo();
      // let url = _baseUrl+`/dev/cus/customData/gys/getData.do?single=true&_winid=w4583&_t=318472&pageIndex=0&pageSize=20&Q_CJMC__S_LK=${this.searchVal}`;
      // this.$ajax.post(url).then((res)=>{
      //   this.data = res.data.data;
      //   this.data.map((item)=>{
      //     if (JSON.stringify(this.mount).indexOf(item.identifyLabel)==-1) {
      //       this.mount.push(item);
      //     }
      //   })
      // })
    },

    //物品
    onRadio(item) {
      console.log(item);
    },
    onInfo(item) {
      console.log(item);
      this.showInfo = true;
      this.gysInfoData = item;
    },
    //确定
    onSelects() {
      // console.log(this.result)
      this.$emit("tdObj", this.result);
    },
    onClear() {
      this.pageNum = 1;
      this.searchVal = "";
      this.getCgwpInfo();
    }
  }
};