/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import MytableRadio from "../../common/hksqRadio/MytableRadio";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, convertKey, format, unique, convertCurrency } from "@/assets/app.js";
export default {
  name: "lcqdHksq",
  components: {
    LCFQheader,
    MytableRadio
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "汇款申请单",
      //下拉
      showHKDW: false,
      showZFFS: false,
      columnsHKDW: [],
      columnsZFFS: [],
      showHkrq: false,
      showListpage: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showGinfo: false,
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      actDefId: "",
      resultFa: [],
      wpInfoData: {},
      data: {},
      zffs: "",
      jedx: ""
    };
  },
  created() {
    this.cgORxj();
    this.initBySolInstId();
  },
  mounted() {
    this.getPopup();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });
    },
    async cgORxj() {
      let busad = this.$route.params.busad;
      if (typeof busad != "undefined") {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          this.data = res.data;
          let urlUser1 = _baseUrl + `/mobile/oa/getUsersSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
          this.$ajax.post(urlUser1).then(resdlr => {
            let dlr = [{
              id: resdlr.data.rowList[0].userId,
              text: resdlr.data.rowList[0].fullname
            }];
            this.data.dlrName = JSON.stringify(dlr);
          });
        });
      } else {
        this.getUser();
      }
    },
    //获取用户信息
    getUser() {
      this.data.cgsqr = RxUtil.getCache("userName");
    },
    getPopup() {
      let urlhkdw = _baseUrl + "/mobile/component/ajax/getDicJsonsByKey.do?dic=HKDW";
      this.$ajax.post(urlhkdw).then(res => {
        this.columnsHKDW = convertKey(res.data.data, ['text', 'key']);
      });
      let urlhthi = _baseUrl + "/mobile/component/ajax/getDicJsonsByKey.do?dic=FKFS";
      this.$ajax.post(urlhthi).then(res => {
        this.columnsZFFS = convertKey(res.data.data, ['text', 'key']);
      });
    },
    onConfiHKDW(value) {
      this.data.hksw_name = value.text;
      this.showHKDW = false;
    },
    onConfiZFFS(value) {
      this.data.zffs_name = value.text;
      this.zffs = value.key;
      this.showZFFS = false;
    },
    dayTime(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      return year + "-" + month + "-" + date;
    },
    onConfirmHkrq(timestamp) {
      this.data.hkrq = this.dayTime(timestamp);
      this.showHkrq = false;
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdObj(item) {
      this.data.sksw_name = item.CJMC_;
      this.data.zh = item.ZH_;
      this.data.khx = item.KHYH_;
      this.showListpage = false;
    },
    onJedx() {
      this.jedx = convertCurrency(this.data.je);
    },
    datas() {
      let fqr = JSON.parse(this.data.fqr_name);
      let qf = JSON.parse(this.data.qf_name);
      let sh = JSON.parse(this.data.sh_name);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            hksw: this.data.sksw_name,
            hksw_name: this.data.hksw_name,
            zffs: this.zffs,
            zffs_name: this.data.zffs_name,
            hkrq: this.data.hkrq,
            sksw: this.data.sksw_name,
            sksw_name: this.data.sksw_name,
            zh: this.data.zh,
            khx: this.data.khx,
            je: this.data.je,
            nr: this.data.nr,
            fplx: "",
            fqr: fqr[0].id,
            fqr_name: fqr[0].text,
            qf: qf[0].id,
            qf_name: qf[0].text,
            sh: sh[0].id,
            sh_name: sh[0].text,
            fplx_name: ""
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      if (this.data.je != "") {
        var params = this.datas();
        var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
        this.$dialog.loading.open('正在启动流程,请稍候...');
        this.$ajax.post(url, params).then(res => {
          this.$dialog.loading.close();
          var data = res.data;
          if (data.success) {
            this.$store.commit('cleanInstData');
            this.successToast("启动成功！", 1000, 'index', {
              from: 'startForm'
            });
          } else {
            this.errorToast("启动失败！" + data.data, "1800");
          }
        });
      } else {
        this.errorToast("金额不能为空！");
      }
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};